import React from "react";
import PropTypes from "prop-types";
import { IconContext } from "react-icons";

import { FaFacebook, FaLinkedin, FaGithub, FaTwitter, FaEnvelope, FaInstagram, FaBitbucket } from 'react-icons/fa';

const Hero = props => {
  const { scrollToContent, backgrounds, theme } = props;

  return (
    <React.Fragment>
      <section className="hero">
        <div id="vidcontainer">
          <video playsInline autoPlay muted loop poster="./video/bg.jpg" id="bgvid">
            <source src="./video/bg.webm" type="video/webm"/>
            <source src="./video/bg.mp4" type="video/mp4"/>
            <source src="./video/bg.ogv" type="video/ogg"/>
          </video>
        </div>
        <h1>
          Mike Stratigiou
        </h1>
        <div>
          <IconContext.Provider value={{ className: 'icons', color: 'white', size: '1.5em', style: { margin: '10px', verticalAlign: 'middle' } }}>
            <a href="mailto:mike.strat[at]gmail[dot]com" target="_blank"><FaEnvelope /></a><a href="https://www.linkedin.com/in/stratigiou/" target="_blank"><FaLinkedin /></a><a href="https://bitbucket.com/gomikestrat" target="_blank"><FaBitbucket /></a><a href="https://github.com/gomikestrat" target="_blank"><FaGithub /></a><a href="https://www.facebook.com/gomikestrat" target="_blank"><FaFacebook /></a><a href="https://twitter.com/gomikestrat" target="_blank"><FaTwitter /></a><a href="https://instagram.com/gomikestrat" target="_blank"><FaInstagram /></a>
          </IconContext.Provider>
        </div>
      </section>

      {/* --- STYLES --- */}
      <style jsx>{`

        :global(.icons) {
          -webkit-transition: 0.35s;
          transition: 0.35s;
        }

        :global(.icons:hover) {
            -webkit-filter: drop-shadow(0px 0px 4px rgba(255,255,255,1)) !important;
            filter: drop-shadow(0px 0px 4px rgba(255,255,255,1)) !important;
        }

        .hero {
          align-items: center;
          display: flex;
          flex-flow: column nowrap;
          justify-content: center;
          max-height: 50%;
          min-height: 50vh;
          background: initial !important;
          padding-top: ${theme.header.height.homepage};
          height: 50vh;
        }

        #vidcontainer {
          overflow: hidden;
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 50%;
          z-index: -1;
        }

        #bgvid {
          height: 100%;
          width: 177.77777778vh;
          min-width: 100%;
          min-height: 56.25vw;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }

        h1 {
          text-align: center;
          font-size: ${theme.hero.h1.size};
          margin: ${theme.space.stack.l};
          color: ${theme.hero.h1.color};
          line-height: ${theme.hero.h1.lineHeight};
          text-remove-gap: both 0 "Open Sans";

          :global(strong) {
            position: relative;

            &::after,
            &::before {
              content: "›";
              color: ${theme.text.color.attention};
              margin: 0 ${theme.space.xs} 0 0;
              text-shadow: 0 0 ${theme.space.s} ${theme.color.neutral.gray.k};
            }
            &::after {
              content: "‹";
              margin: 0 0 0 ${theme.space.xs};
            }
          }
        }

        button {
          background: ${theme.background.color.brand};
          border: 0;
          border-radius: 50%;
          font-size: ${theme.font.size.m};
          padding: ${theme.space.s} ${theme.space.m};
          cursor: pointer;
          width: ${theme.space.xl};
          height: ${theme.space.xl};

          &:focus {
            outline-style: none;
            background: ${theme.color.brand.primary.active};
          }

          :global(svg) {
            position: relative;
            top: 5px;
            fill: ${theme.color.neutral.white};
            stroke-width: 40;
            stroke: ${theme.color.neutral.white};
            animation-duration: ${theme.time.duration.long};
            animation-name: buttonIconMove;
            animation-iteration-count: infinite;
          }
        }

        @keyframes buttonIconMove {
          0% {
            transform: translateY(0);
          }
          50% {
            transform: translateY(-10px);
          }
          100% {
            transform: translateY(0);
          }
        }

        @from-width tablet {
          .hero {
            background-image: url(${backgrounds.tablet});
          }

          h1 {
            max-width: 90%;
            font-size: ${`calc(${theme.hero.h1.size} * 1.3)`};
          }

          button {
            font-size: ${theme.font.size.l};
          }
        }

        @from-width desktop {
          .hero {
            background-image: url(${backgrounds.desktop});
          }

          h1 {
            max-width: 80%;
            font-size: ${`calc(${theme.hero.h1.size} * 1.5)`};
          }

          button {
            font-size: ${theme.font.size.xl};
          }
        }
      `}</style>
    </React.Fragment>
  );
};

Hero.propTypes = {
  scrollToContent: PropTypes.func.isRequired,
  backgrounds: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

export default Hero;
